<template>
    <div>
        <div class="container py-5 my-0 my-sm-5 px-4">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="row align-items-center mb-0 pb-5">
                        <div class="col-6 col-sm-6 col-md-6 text-left">
                            <div class="logo-wrapper"><img src="@/assets/img/bau-logo-for-light.svg"/></div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 text-right">
                            <h4>{{ $t('graduate_application_form').toUpper() }}</h4>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-4">
                    <hr />
                </div>
                <div class="col-8 offset-2 mt-4">
                    <b-card no-body class="overflow-hidden" style="max-width: 740px;">
                        <b-row no-gutters>
                            <b-col md="6">
                                <b-card-img :src="image" alt="Image" class="rounded-0"></b-card-img>
                            </b-col>
                            <b-col md="6">
                                <b-card-body :title="$t('past_application_date').toUpper()">
                                    <b-card-text>
                                        {{$t('past_application_date_desc')}}
                                    </b-card-text>
                                </b-card-body>
                            </b-col>
                        </b-row>
                    </b-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import image from "@/assets/img/application-form.jpg"

export default {
    components: {

    },
    metaInfo() {
        return {
            title: this.$t('graduate_application_form')
        }
    },
    data() {
        return {
            image
        }
    },
    created() {

    },
}
</script>

